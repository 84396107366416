<template>
  <div>
    <v-jsoneditor v-model="sql" class="pa-4" :plus="false" :options="options" />
  </div>
</template>

<script>
export default {
  props: {
    sql: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    options: { mode: 'view', mainMenuBar: false }
  })
}
</script>
